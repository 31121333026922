<template>
  <div class="PosenetImage">
    <p>
      <button @click="placeMask">Place Mask</button>
    </p>
    <p>
      <button @click="prevMask">Previous Mask</button>
      <button @click="nextMask">Next Mask</button>
    </p>
    <p>
      <button class="arrow" @click="moveLeft">←</button>
      <button  class="arrow" @click="moveRight">→</button>
      <button  class="arrow" @click="moveUp">↑</button>
      <button  class="arrow" @click="moveDown">↓</button>
    </p>
    <p>
      <button class="arrow" @click="enlarge">↟ Enlarge</button>
      <button class="arrow" @click="reduce">↡ Reduce</button>
    </p>
  </div>
</template>

<script>
const posenet = require('@tensorflow-models/posenet')

export default {
  name: 'PosenetImage',
  data() {
    return {
      currentMask: null,
      currentCoordinates: null,
      targetField: null,
      targetImageContainer: null,
      targetImage: null,
      posenetInstance: null,
      maskContainer: null,
      moveMultiplier: 1,
      resizeMultiplier: 2,
      coords: {},
      mask: null
    }
  },
  props: {
    masks: {
      type: Array,
      required: true
    },
    textField: {
      required: true
    },
    imageContainer: {
      required: true
    }
  },
  mounted: function () {
    // load posenet

    if (!this.posenetInstance) {
      this.posenetInstance = posenet.load({
        architecture: 'MobileNetV1',
        outputStride: 16,
        inputResolution: {width: 640, height: 480},
        multiplier: 0.75
      })
    }

    // setup variables for DIVs etc
    if (typeof this.textField === 'string') {
      this.targetField = document.querySelector(this.textField)
    } else {
      this.targetField = this.textField
    }
    if (typeof this.imageContainer === 'string') {
      this.targetImageContainer = document.querySelector(this.imageContainer)
    } else {
      this.targetImageContainer = this.imageContainer
    }
    if (!this.targetImageContainer || !this.targetField) {
      this.logError('Target image container or target field not found')
    } else {
      this.targetImage = this.targetImageContainer.querySelector('img')
      if (!this.targetImage) {
        this.logError('No image in target image container')
      }
    }
    // add a .face DIV image to container
    this.maskContainer = document.createElement('div')
    this.maskContainer.classList.add('maskContainer')
    this.targetImageContainer.append(this.maskContainer)

  },
  methods: {
    logError (msg) {
      console.error(`PosenetImage: ${msg}`)
    },
    logMessage () {
      console.log(`PosenetImage: `, ...arguments)
    },
    saveJson () {
      this.targetField.innerHTML = JSON.stringify(this.coords)
    },
    placeMask () {
      if (this.currentMask === null  ) {
        this.currentMask = Math.floor(Math.random() * Math.floor(this.masks.length - 1))
      }

      let key = this.targetImage.src
      let myPose
      if (window.localStorage.getItem(key)) {
        myPose = window.localStorage.getItem(key)
        let pose0 = JSON.parse(myPose)
        this.logMessage(pose0)
        this.positionMask(pose0)
      } else {
        // posenet is a promise!
        this.posenetInstance.then((net) => {
          this.logMessage('posenet loaded')
          const pose = net.estimateMultiplePoses(this.targetImage, {
            maxDetections: 1,
            flipHorizontal: false
          })
          return pose
        }).then(function (pose) {
          // only save the first pose
          let myPose = JSON.stringify(pose[0])
          window.localStorage.setItem(key, myPose)
          this.logMessage(pose[0])
          this.positionMask(pose[0])
        }.bind(this))
      }
    },
    prevMask () {
      let nextIndex = this.currentMask - 1
      this.logMessage('prev index', this.currentMask, nextIndex)
      if (nextIndex < 0) {
        nextIndex = this.masks.length - 1
      }
      this.currentMask = nextIndex
      this.changeMask(nextIndex)
    },
    nextMask () {
      let nextIndex = this.currentMask + 1
      this.logMessage('next index', this.currentMask, nextIndex)
      if (nextIndex >= this.masks.length) {
        nextIndex = 0
      }
      this.currentMask = nextIndex
      this.changeMask(nextIndex)
    },
    changeMask (index) {
      this.currentMask = index
      console.warn('changeMask', mask, this.mask.src)
      let mask = this.masks[this.currentMask]
      this.mask.src = mask
    },
    getKeypoint (pose, keyPoint) {
      for (let i = 0; i < pose.keypoints.length; i++) {
        if (pose.keypoints[i].part === keyPoint) {
          return pose.keypoints[i]
        }
      }
      return false
    },
    getKeypointsToUse (currentPose) {
      // check security of relevant points
      let acceptableConfidence = 0.6
      let points = null
      let mode = "front"
      if (this.getKeypoint(currentPose, 'leftEar').score > acceptableConfidence && this.getKeypoint(currentPose, 'rightEar').score > acceptableConfidence) {
        // have we got ears?
        points = [
          this.getKeypoint(currentPose, 'leftEar').position,
          this.getKeypoint(currentPose, 'rightEar').position
        ]
      }  else if (this.getKeypoint(currentPose, 'leftEar').score > acceptableConfidence && this.getKeypoint(currentPose, 'rightEye').score > acceptableConfidence) {
        this.logMessage('left ear right eye')
        // position by one year, one eye
        points = [
          this.getKeypoint(currentPose, 'leftEar').position,
          this.getKeypoint(currentPose, 'rightEye').position
        ]
        mode = "turned"
      } else if (this.getKeypoint(currentPose, 'leftEye').score > acceptableConfidence && this.getKeypoint(currentPose, 'rightEar').score > acceptableConfidence) {
        this.logMessage('left eye right ear')
        // position by one year, one eye
        points = [
          this.getKeypoint(currentPose, 'leftEye').position,
          this.getKeypoint(currentPose, 'rightEar').position
        ]
        mode = "turned"
      } else if (this.getKeypoint(currentPose, 'leftEye').score > acceptableConfidence && this.getKeypoint(currentPose, 'leftEar').score > acceptableConfidence) {
        this.logMessage('left eye left ear')
        // position by left features
        points = [
          this.getKeypoint(currentPose, 'leftEar').position,
          this.getKeypoint(currentPose, 'leftEye').position
        ]
        mode = "profile"
      } else if (this.getKeypoint(currentPose, 'rightEye').score > acceptableConfidence && this.getKeypoint(currentPose, 'rightEar').score > acceptableConfidence) {
        this.logMessage('right eye right ear')
        // position by right features
        points = [
          this.getKeypoint(currentPose, 'rightEye').position,
          this.getKeypoint(currentPose, 'rightEar').position
        ]
        mode = "profile"
      } else if (this.getKeypoint(currentPose, 'leftEye').score > acceptableConfidence && this.getKeypoint(currentPose, 'rightEye').score > acceptableConfidence) {
        this.logMessage('eyes OK')
        // position by eye position
        points = [
          this.getKeypoint(currentPose, 'leftEye').position,
          this.getKeypoint(currentPose, 'rightEye').position
        ]
        mode = "eyesOnly"
      }
      if (points) {
        return {
          points: points,
          mode: mode
        }
      } else {
        return null
      }
    },
    positionMask (results) {
      let currentPose = results
      this.logMessage(currentPose)

      let keyPointsToUse = this.getKeypointsToUse(currentPose)

      if (keyPointsToUse) {
        this.positionOverlay(keyPointsToUse.points, keyPointsToUse.mode)
      } else {
        this.logError('Could not get keypoints to use')
      }
    },
    insertMask() {
      let mask = this.masks[this.currentMask]
      let img = document.createElement('img')
      img.src =  mask
      /*if (mask.width !== 1) {
        img.style.width = 100 * mask.width + "%"
        img.style.transform = `translateX(${-(mask.width -1)/2*100}%)`
      }*/

      // remove previous masks
      while (this.maskContainer.firstChild) {
        this.maskContainer.removeChild(this.maskContainer.firstChild);
      }
      this.maskContainer.append(img)
      return img
    },
    positionOverlay(points, mode) {
      this.logMessage('positionOverlay', points, mode)
      // position overlay at center of points and
      // size overlay according to distance between points

      let widthMultiplier
      switch (mode) {
        case 'profile':
          widthMultiplier = 6
          break
        case 'turned':
          widthMultiplier = 4
          break
        case 'eyesOnly':
          widthMultiplier = 4
          break
        case 'front':
        default:
          widthMultiplier = 3
      }

      let center = this.getCenterOfPoints(...points)
      let width = this.getDistanceBetweenPoints(...points) * widthMultiplier
      let angle = this.getAngleBetweenPoints(...points)

      this.logMessage('center', center)
      this.logMessage('width', width)

      this.logMessage('image width ', this.targetImage.width)

      this.mask = this.insertMask()

      function getProportionalWidth (width, img) {
        let retVal = width * 100 / img.width + "%"
        // this.logMessage(`width: ${width}, imgWidth, ${img.width}, retVal, ${retVal}`)
        return retVal
      }
      function getProportionalHeight (height, img) {
        let retVal = height * 100 / img.height + "%"
        // this.logMessage(`width: ${width}, imgWidth, ${img.width}, retVal, ${retVal}`)
        return retVal
      }

      let coords = {
        width: getProportionalWidth(width, this.targetImage),
        height: getProportionalHeight(width, this.targetImage),
        top: getProportionalHeight(center.y - width/2, this.targetImage),
        left: getProportionalWidth(center.x - width/2, this.targetImage),
        angle: angle,
      }


      this.coords = coords
      this.positionImageFromCoords()

    },
    moveLateral(forward) {
      let coords = this.coords
      let plainLeft = parseFloat(this.coords.left)
      if (forward) {
        coords.left = plainLeft + this.moveMultiplier + '%'
      } else {
        coords.left = plainLeft - this.moveMultiplier + '%'
      }
      this.coords = coords
      this.positionImageFromCoords()
    },
    moveRight () {
      this.moveLateral (true)
    },
    moveLeft () {
      this.moveLateral (false)
    },
    moveVertical(forward) {
      let coords = this.coords
      let plainTop = parseFloat(this.coords.top)
      if (forward) {
        coords.top = plainTop + this.moveMultiplier + '%'
      } else {
        coords.top = plainTop - this.moveMultiplier + '%'
      }
      this.coords = coords
      this.positionImageFromCoords()
    },
    moveUp () {
      this.moveVertical (false)
    },
    moveDown () {
      this.moveVertical (true)
    },
    enlarge () {
      this.changeSize(false)
    },
    reduce () {
      this.changeSize(true)
    },
    changeSize (reduce) {
      let coords = this.coords
      let plainWidth = parseFloat(this.coords.width)
      let plainLeft = parseFloat(this.coords.left)
      let plainHeight = parseFloat(this.coords.height)
      let plainTop = parseFloat(this.coords.top)
      if (!reduce) {
        coords.width = plainWidth + this.resizeMultiplier + '%'
        coords.height = plainHeight + this.resizeMultiplier + '%'
        coords.left = plainLeft - this.resizeMultiplier/2 + '%'
        coords.top = plainTop - this.resizeMultiplier/2 + '%'
      } else {
        coords.width = plainWidth - this.resizeMultiplier + '%'
        coords.height = plainHeight - this.resizeMultiplier + '%'
        coords.left = plainLeft + this.resizeMultiplier/2 + '%'
        coords.top = plainTop + this.resizeMultiplier/2 + '%'
      }
      this.coords = coords
      this.positionImageFromCoords()


    },
    positionImageFromCoords () {

      this.saveJson()

      let mask = this.mask
      let coords = this.coords

      mask.style.width = coords.width
      mask.style.height = coords.height
      mask.style.top = coords.top
      mask.style.left = coords.left
      // rotate to match mask rotation

      mask.style.transform = `rotateZ(${coords.angle}deg)`
    },
    getDistanceBetweenPoints(a, b) {
      let x = a.x - b.x
      let y = a.y - b.y
      return Math.hypot(x, y)
    },
    getCenterOfPoints(a, b) {
      return {
        x: (a.x + b.x) / 2,
        y: (a.y + b.y) / 2
      }
    },
    getAngleBetweenPoints(b, a) {
      return  Math.atan2(b.y - a.y, b.x - a.x) * 180 / Math.PI;
    }
  }


}
</script>

<style  lang="scss">
  .content {
    button.arrow {
      padding: 0 20px;
    }
  }
</style>
