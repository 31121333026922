<template>
  <div class="content">
    <div class="controls">
      <p><button @click="changeImage(true)">Previous Photo</button>
        <button @click="changeImage(false)">Next Photo</button></p>
    </div>
    <div class="imageAndForm">
      <div class="imageContainer">
        <img :src="currentImage" alt="">

      </div>
      <div class="commandsContainer controls">
        <form @submit="save">
          <textarea name="params"></textarea>
          <p><button>SAVE ↲</button></p>
        </form>
        <posenet-image :masks="masks" :text-field="textField" :imageContainer="imageContainer"></posenet-image>
      </div>

    </div>
  </div>
</template>

<script>
import PosenetImage from './components/PosenetImage'
const images = require('./data/ovs-various/*.jpg')
const masks = require('./data/svg/new/*.svg')
import Vue from "vue"

export default Vue.extend({
  name: "PosenetTestRoot",
  components: {PosenetImage},
  computed: {
    currentImage() {
      return this.images[this.currentIndex]
    }
  },
  mounted: function () {
  },
  methods: {
    save () {
      alert("Please implement your save action and form target")
      return false
    },
    changeImage(prev = false) {
      let nextIndex
      if (prev) {
        nextIndex = this.currentIndex - 1
        // console.log('previous index', this.currentIndex,  nextIndex)
        if (nextIndex < 0) {
          nextIndex = this.images.length - 1
        }
      } else {
        nextIndex = this.currentIndex + 1
        // console.log('next index', this.currentIndex,  nextIndex)
        if(nextIndex >= this.images.length) {
          nextIndex = 0
        }
      }
      this.currentIndex = nextIndex
    }
  },
  data() {
    return {
      masks:  Object.values(masks),
      currentIndex: 0,
      images: Object.values(images),
      bundler: "Parcel",
      textField: 'textarea[name="params"]',
      imageContainer: '.imageContainer'
    }
  }
});
</script>

<style lang="scss">
.content {
  .imageAndForm {
    display: flex;
    align-items: flex-start;
    textarea {
      height: 200px;
    }
  }
  .imageContainer {
    position: relative;
  }
  .commandsContainer{
    margin-left: 50px;
  }
  button {
    background: darkcyan;
    color: white;
    font-size: 20px;
    padding: 5px;
    border-radius: 5px;
  }
  .maskContainer {
    border: 2px dotted red;
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: auto;
      transform-origin: center center;
      position: absolute;
    }

  }
  .controls {
    position: relative;
    z-index: 1;
  }
}
</style>
